import axios from 'axios'
import type { ItemUnitValue, LayoutItem } from '@js/model/datasheet'
import type { HydraCollectionResponse } from '@js/types'
import type { Period } from '@js/model/period'
import type { Unit } from '@js/model/unit'

const basePath = '/api/item-values'

export type ItemUnitValueCollectionQuery = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  search?: string | Array<string>
  'item.id'?: LayoutItem['id']
  'item.refId'?: LayoutItem['refId']
  'unit.id'?: Unit['id']
  'unit.refId'?: Unit['refId']
  'period.id'?: Period['id']
  'period.name'?: Period['name']
}

export const itemUnitValueApi = {
  fetchById: function (id: ItemUnitValue['id']) {
    return axios.get<ItemUnitValue>(`${basePath}/${id}`)
  },
  fetchByQuery(query?: ItemUnitValueCollectionQuery) {
    return axios.get<HydraCollectionResponse<ItemUnitValue>>(basePath, {
      params: { ...query },
    })
  },
  basePath,
}
