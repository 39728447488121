import axios from 'axios'
import Routing from '@js/Routing'
import { type Document, documentSectionTypeToShortName } from '@js/model/document'
import { type TaskExtra, type TaskShortName } from '@js/model/task'
import type { MessageBag } from '@js/stores/notifications'
import type { Attachment } from '@js/model/attachment'
import type { DocumentSection } from '@js/model/document'
import type { Country } from '@js/model/country'

export type DocumentEditDataResponse = {
  newSectionPath: string
  attachments: Array<Attachment>
  sections: Array<DocumentSection>
  documentName: string
  sectionsHtml: string
  routes: { edit: string }
  userCanEditContent: boolean
  userCanEditConfiguration: boolean
}

export type DocumentSectionPlacement = 'before' | 'after' | 'subsection-of'

export type NewSectionOptions = {
  placement: DocumentSectionPlacement
  referenceSectionId: DocumentSection['id']
}

function fetchByTask(shortName: TaskShortName, id: TaskExtra['taskTypeId']) {
  return axios.get<DocumentEditDataResponse>(
    Routing.generate('u2_tasktype_editdocument_data', {
      shortName,
      id,
    })
  )
}

function fetchRenderedSections(shortName: TaskShortName, id: TaskExtra['taskTypeId']) {
  return axios.get<Array<{ id: DocumentSection['id']; content: string }>>(
    Routing.generate('u2_tasktype_renderdocumentsections', {
      shortName,
      id,
    })
  )
}

function addInitialSection(initialSectionData: {
  shortName: TaskShortName
  documentId: TaskExtra['taskTypeId']
  name: DocumentSection['name']
  content?: DocumentSection['content']
}) {
  const sectionFormData = new FormData()
  sectionFormData.append(
    'document_section_initial_add_form[sectionToManipulate][name]',
    initialSectionData.name
  )
  if (initialSectionData.content) {
    sectionFormData.append(
      'document_section_initial_add_form[sectionToManipulate][content]',
      initialSectionData.content
    )
  }

  return axios.post<{ success: boolean; messages: MessageBag }>(
    Routing.generate('u2_documentsection_newinitialsection', {
      shortName: initialSectionData.shortName,
      documentId: initialSectionData.documentId,
    }),
    sectionFormData
  )
}

function addSection(
  section: Partial<DocumentSection> & Pick<DocumentSection, '@type' | 'name'>,
  options: NewSectionOptions
) {
  const { placement, referenceSectionId } = options
  const sectionFormData = new FormData()
  sectionFormData.append('document_section_add_form[sectionToManipulate][name]', section.name)
  return axios.post<{ success: boolean; messages: MessageBag }>(
    Routing.generate('u2_documentsection_create', {
      placement,
      referenceSectionId,
      shortName: documentSectionTypeToShortName[section['@type']],
    }),
    sectionFormData
  )
}

function excludeSection(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_exclude', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

function includeSection(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_include', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

function allowSectionEdit(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_allowedit', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

function preventSectionEdit(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_preventedit', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

function requireSection(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_require', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

function unrequireSection(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_unrequire', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

function fetchBmfCountries(taskTypeId: TaskExtra['taskTypeId']) {
  return axios.get<{ countries: Array<Country> }>(
    Routing.generate('u2_xbrl_bmf_downloadlistjson', { id: taskTypeId })
  )
}
function downloadOecdReport(taskTypeId: TaskExtra['taskTypeId']) {
  return axios.post<{ xml: string; name: string }>(
    `/api/country-by-country-reports/${taskTypeId}/xbrl-download`,
    {}
  )
}

function downloadBmfReport(taskTypeId: TaskExtra['taskTypeId'], countryId: Country['id']) {
  return axios.post<{ xml: string; name: string }>(
    `/api/country-by-country-reports/${taskTypeId}/country/${countryId}/xbrl-download`,
    {}
  )
}

function downloadAttachments(params: { shortName: TaskShortName; documentId: Document['id'] }) {
  return axios.post(
    Routing.generate('u2_attachmentsappendix_downloadarchive', params),
    {},
    { responseType: 'blob' }
  )
}

export const documentApi = {
  addInitialSection,
  addSection,
  allowSectionEdit,
  downloadAttachments,
  downloadBmfReport,
  downloadOecdReport,
  excludeSection,
  fetchBmfCountries,
  fetchByTask,
  fetchRenderedSections,
  includeSection,
  preventSectionEdit,
  requireSection,
  unrequireSection,
}
