import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { datasheetApi } from '@js/api/datasheetApi'
import { layoutFieldApi } from '@js/api/layoutFieldApi'
import type { MaybeRef } from '@vueuse/core'
import type { Datasheet, Field } from '@js/model/datasheet'

export const layouts = createQueryKeys('layouts', {
  all: { queryKey: null, queryFn: () => datasheetApi.fetchAll().then((response) => response.data) },
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      datasheetApi.fetchByQuery({
        ...unref(filters),
      }),
  }),
  single: (id: MaybeRef<Datasheet['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => datasheetApi.fetchById(unref(id)).then((response) => response.data),
      contextQueries: {
        template: {
          queryKey: [id],
          queryFn: () =>
            datasheetApi.downloadLayoutTemplate(unref(id)).then((response) => response.data),
        },
        fields: {
          queryKey: [id],
          queryFn: () => layoutFieldApi.fetchAll(unref(id)),
          contextQueries: {
            single: (fieldId: MaybeRef<Field['id']>) => {
              return {
                queryKey: [fieldId, id],
                queryFn: () =>
                  layoutFieldApi
                    .fetchById(unref(fieldId), unref(id))
                    .then((response) => response.data),
              }
            },
          },
        },
        layoutCollections: {
          queryKey: [id],
          queryFn: () =>
            datasheetApi.fetchLayoutCollections(unref(id)).then((response) => response.data),
        },
      },
    }
  },
})
