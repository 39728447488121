import { useQueryClient } from '@tanstack/vue-query'
import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import { type SelectedView, useLayoutParametersStore } from '@js/stores/layout-parameters'
import { datasheetCollectionApi } from '@js/api/datasheetCollectionApi'
import { queries } from '@js/query'
import { useNotificationsStore } from '@js/stores/notifications'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'
import type { LayoutCollection } from '@js/model/layoutCollection'
import type { Datasheet, Field } from '@js/model/datasheet'
import type { Unit } from '@js/model/unit'
import type { Period } from '@js/model/period'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'

export default [
  {
    component: () => import('@js/pages/layout/LayoutCollectionEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'DatasheetCollectionEdit',
    path: '/configuration/datasheets/collections/:id',
    props: (route: RouteLocation) => ({
      id: route.params.id,
    }),
  },

  {
    component: () => import('@js/pages/layout/LayoutCollectionNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'DatasheetCollectionNew',
    path: '/configuration/datasheets/collections/new',
  },
  {
    component: () => import('@js/pages/layout/LayoutCollectionList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.datasheets.datasheet_collection.plural'),
      },
    },
    name: 'DatasheetCollectionList',
    path: '/configuration/datasheets/collections',
  },

  {
    component: () => import('@js/pages/layout/LayoutCollectionGroupViewBreakdown.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
    },
    name: 'DatasheetCollectionGroupViewBreakdown',
    path: '/datasheets/breakdown/unit-hierarchy/by-unit',
  },
  {
    component: () => import('@js/pages/layout/ItemCountryReport.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
    },
    name: 'ItemCountryReport',
    path: '/datasheets/breakdown/unit-hierarchy/by-country',
  },

  {
    component: () => import('@js/pages/layout/DatasheetCollectionSheetView.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
    },
    name: 'DatasheetCollectionSheetView',
    path: '/datasheets/collections/:id/sheets/:sheetId',
    props: (to: RouteLocation) => ({
      layoutCollection: to.meta.props?.layoutCollection,
      layout: to.meta.props?.selectedLayout,
    }),
    beforeEnter: async (to) => {
      const layoutParamStore = useLayoutParametersStore()
      layoutParamStore.parameters.layoutCollection = String(to.params.id)
      layoutParamStore.parameters.layout = to.params.sheetId ? Number(to.params.sheetId) : undefined
      layoutParamStore.parameters.period = to.query.period ? Number(to.query.period) : undefined

      const view = 'unit' in to.query || !('unitHierarchy' in to.query) ? 'unit' : 'group'

      if (view === 'unit') {
        layoutParamStore.parameters.unit = to.query.unit ? Number(to.query.unit) : undefined
      }

      if (view === 'group') {
        layoutParamStore.parameters.unitHierarchy = to.query.unitHierarchy
          ? Number(to.query.unitHierarchy)
          : undefined
      }

      layoutParamStore.parameters.field = to.query.field ? Number(to.query.field) : undefined

      const queryClient = useQueryClient()
      const assignedLayouts = await queryClient
        .ensureQueryData(
          queries.layoutCollections.single(layoutParamStore.parameters.layoutCollection)._ctx
            .layouts
        )
        .then(({ 'hydra:member': layouts }) => layouts)

      const layout = assignedLayouts.find((layout) => layout.id === +to.params.sheetId)
      if (layout === undefined) {
        useNotificationsStore().addError(
          Translator.trans('u2.datasheet_collection.datasheet_no_found_or_missing_permission')
        )

        return buildNoLayoutSelectedRoute(view)
      }

      const { data: layoutCollection } = await datasheetCollectionApi.fetchById(
        layoutParamStore.parameters.layoutCollection
      )
      to.meta.props = { layoutCollection, selectedLayout: layout }
    },
  },
  {
    component: () => import('@js/pages/layout/LayoutCollectionNoLayoutSelected.vue'),
    name: 'DatasheetCollectionNoSheetSelected',
    path: '/datasheets/collections/:id',
    meta: {
      layout: BaseLayout,
    },
    props: (to: RouteLocation) => ({
      layoutCollection: to.meta.props?.layoutCollection,
    }),
    beforeEnter: async (to: RouteLocation) => {
      const layoutParamStore = useLayoutParametersStore()
      layoutParamStore.parameters.layoutCollection = String(to.params.id)
      layoutParamStore.parameters.layout = undefined

      if (to.query.useCurrentParams) {
        return buildNoLayoutSelectedRoute()
      }

      layoutParamStore.parameters.period = to.query.period ? Number(to.query.period) : undefined

      const view = 'unit' in to.query || !('unitHierarchy' in to.query) ? 'unit' : 'group'
      if (view === 'unit') {
        layoutParamStore.parameters.unit = to.query.unit ? Number(to.query.unit) : undefined
      }

      if (view === 'group') {
        layoutParamStore.parameters.unitHierarchy = to.query.unitHierarchy
          ? Number(to.query.unitHierarchy)
          : undefined
      }

      const { data: layoutCollection } = await datasheetCollectionApi.fetchById(
        layoutParamStore.parameters.layoutCollection
      )

      to.meta.props = { layoutCollection }
    },
  },
] as Array<RouteRecordRaw>

export type DatasheetRouteParameters = {
  layoutCollectionId: LayoutCollection['id']
  layoutId?: Datasheet['id']
  fieldId?: Field['id']
  unitId?: Unit['id'] | '' // Allow empty string to keep the parameter in the URL. `null` or `undefined` would cause the parameter to be removed.
  periodId?: Period['id']
  hierarchyId?: UnitHierarchy['id'] | '' // Allow empty string to keep the parameter in the URL. `null` or `undefined` would cause the parameter to be removed.
}
export const buildDatasheetRoute = (parameters: DatasheetRouteParameters) => {
  const layoutParamStore = useLayoutParametersStore()

  const queryParameters = {
    field: parameters.fieldId ?? layoutParamStore.parameters.field,
    period: parameters.periodId ?? layoutParamStore.parameters.period,
  } as {
    period: Period['id']
    unit?: Unit['id'] | ''
    unitHierarchy?: UnitHierarchy['id'] | ''
    field?: Field['id']
  }

  const view = 'unitId' in parameters || !('hierarchyId' in parameters) ? 'unit' : 'group'
  if (view === 'unit') {
    queryParameters.unit = parameters.unitId ?? layoutParamStore.parameters.unit
  }

  if (view === 'group') {
    queryParameters.unitHierarchy =
      parameters.hierarchyId ?? layoutParamStore.parameters.unitHierarchy
  }

  if (parameters.layoutId === undefined) {
    return {
      name: 'DatasheetCollectionNoSheetSelected',
      params: {
        id: parameters.layoutCollectionId,
      },
      query: queryParameters,
    }
  }

  return {
    name: 'DatasheetCollectionSheetView',
    params: {
      id: parameters.layoutCollectionId,
      sheetId: parameters.layoutId,
    },
    query: queryParameters,
  }
}

export const buildNoLayoutSelectedRoute = (view?: SelectedView) => {
  const layoutParamStore = useLayoutParametersStore()
  const queryParameters = {
    period: layoutParamStore.parameters.period,
  } as {
    period: Period['id']
    unit?: Unit['id'] | ''
    unitHierarchy?: UnitHierarchy['id'] | ''
    field?: Field['id']
  }

  if ((view ?? layoutParamStore.parameters.selectedView) === 'unit') {
    queryParameters.unit = layoutParamStore.parameters.unit
  }

  if ((view ?? layoutParamStore.parameters.selectedView) === 'group') {
    queryParameters.unitHierarchy = layoutParamStore.parameters.unitHierarchy
  }

  return {
    name: 'DatasheetCollectionNoSheetSelected',
    params: {
      id: layoutParamStore.parameters.layoutCollection,
    },
    query: queryParameters,
  }
}
