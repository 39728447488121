import { logDeprecatedRouteUsage } from '@js/router/helpers'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'LayoutCollectionEdit',
    path: '/configuration/layout-collection/:id/edit',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetCollectionEdit',
        params: {
          id: to.params.id,
        },
      }
    },
  },

  {
    name: 'LayoutCollectionNew',
    path: '/configuration/layout-collection/new',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetCollectionNew',
      }
    },
  },
  {
    name: 'LayoutCollectionList',
    path: '/configuration/layout-collection',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetCollectionList',
      }
    },
  },
  {
    name: 'LayoutCollectionUnitView',
    path: '/layout-collections/:id/layouts/:layoutId/unit-view',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetCollectionSheetView',
        params: {
          id: to.params.id,
          sheetId: to.params.layoutId,
        },
        query: {
          ...to.query,
        },
      }
    },
  },
  {
    name: 'LayoutCollectionGroupView',
    path: '/layout-collections/:id/layouts/:layoutId/group-view',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetCollectionSheetView',
        params: {
          id: to.params.id,
          sheetId: to.params.layoutId,
        },
        query: {
          ...to.query,
        },
      }
    },
  },
  {
    name: 'LayoutCollectionGroupViewBreakdownLegacy',
    path: '/layout-collections/:id/layouts/:layoutId/group-view/item-breakdown/:itemId',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetCollectionGroupViewBreakdown',
        query: {
          layoutCollection: to.params.id,
          item: to.params.itemId,
          layout: to.params.layoutId,
          ...to.query,
        },
      }
    },
  },
  {
    name: 'LayoutCollectionGroupViewBreakdown',
    path: '/layout-collections/:id/group-view/item-breakdown/:itemId',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetCollectionGroupViewBreakdown',
        query: {
          item: to.params.itemId,
          layoutCollection: to.params.id,
          ...to.query,
        },
      }
    },
  },
  {
    name: 'ItemCountryReportLegacy',
    path: '/layout-collections/:id/item-country-report/:reportId',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'ItemCountryReport',
        query: {
          layoutCollection: to.params.id,
          report: to.params.reportId,
          ...to.query,
        },
      }
    },
  },
  {
    name: 'LayoutCollectionsHome',
    path: '/layout-collections/:id/layouts/:layoutId',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetCollectionSheetView',
        params: {
          id: to.params.id,
          sheetId: to.params.layoutId,
        },
        query: {
          ...to.query,
        },
      }
    },
  },
  {
    name: 'LayoutCollectionNoLayoutSelected',
    path: '/layout-collections/:id/layouts',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetCollectionNoSheetSelected',
        params: {
          id: to.params.id,
        },
        query: {
          ...to.query,
        },
      }
    },
  },
] as Array<RouteRecordRaw>
