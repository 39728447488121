import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { itemApi } from '@js/api/itemApi'
import type { MaybeRef } from '@vueuse/core'
import type { LayoutItem } from '@js/model/datasheet'

export const items = createQueryKeys('items', {
  all: {
    queryKey: null,
    queryFn: () => itemApi.fetchAll().then((response) => response.data),
  },
  single: (id: MaybeRef<LayoutItem['id']>) => ({
    queryKey: [id],
    queryFn: () => itemApi.fetchById(unref(id)).then((response) => response.data),
  }),
})
