import { useQueryClient } from '@tanstack/vue-query'
import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import { queries } from '@js/query'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/ConfigurationData.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'ConfigurationData',
    path: '/configuration-data',
  },
  {
    component: () => import('@js/pages/branch/BranchList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.branch.plural'),
      },
    },
    name: 'BranchList',
    path: '/configuration/branch',
  },
  {
    component: () => import('@js/pages/branch/BranchEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { id: number }) => `${Translator.trans('u2_core.branch')} #${params.id}`,
    },
    name: 'BranchEdit',
    path: '/configuration/branch/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/branch/BranchNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'BranchNew',
    path: '/configuration/branch/new',
  },
  {
    component: () => import('@js/pages/configuration/SystemSettingsEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.edit_system_settings'),
      },
    },
    name: 'SystemSettingsEdit',
    path: '/admin/system-setting/edit',
    beforeEnter: async () => {
      const queryClient = useQueryClient()
      await Promise.all([
        queryClient.fetchQuery(queries.systemSettings.all),
        queryClient.fetchQuery(queries.currencies.all),
        queryClient.fetchQuery(queries.unitProperties.all),
      ])
    },
  },
  {
    component: () => import('@js/pages/configuration/SystemImagesEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.edit_system_images'),
      },
    },
    name: 'SystemImagesEdit',
    path: '/admin/system-images/edit',
    beforeEnter: async () => {
      const queryClient = useQueryClient()
      await Promise.all([
        queryClient.fetchQuery(queries.systemSettings.all),
        queryClient.fetchQuery(queries.currencies.all),
        queryClient.fetchQuery(queries.unitProperties.all),
      ])
    },
  },
] as Array<RouteRecordRaw>
