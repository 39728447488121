<template>
  <div>
    <SystemMessages class="fixed w-full" />

    <AppNotifications />

    <transition
      enter-active-class="ease-out duration-300"
      leave-active-class="ease-in duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      name="fade"
    >
      <div
        v-show="imgLoaded && !isLoading"
        :style="style"
        class="flex min-h-screen flex-col items-center space-y-4 bg-cover bg-no-repeat px-2"
      >
        <div
          class="mx-auto flex max-w-md flex-grow flex-col items-center justify-center sm:w-full sm:max-w-md"
        >
          <!-- Logo -->
          <div class="w-3/4 sm:w-full">
            <img data-app-logo class="mx-auto object-scale-down" :src="loginLogoPath" alt="" />
          </div>

          <!-- Form -->
          <div class="mt-4 w-full rounded-xl bg-white/70 p-5 shadow backdrop-blur-md sm:p-8">
            <slot />
          </div>
        </div>

        <!-- Support -->
        <div
          class="mx-auto flex items-center justify-between space-x-3 rounded-t-xl bg-white/50 px-3 py-1 backdrop-blur-md sm:w-full sm:max-w-sm"
        >
          <router-link class="mt-1 text-gray-800 hover:text-gray-800" :to="{ name: 'SupportInfo' }">
            {{ Translator.trans('u2_core.support') }}
          </router-link>

          <a
            href="https://universalunits.com"
            target="_blank"
            class="text-gray-800 hover:text-gray-800"
          >
            <U2Logo />
          </a>

          <span class="mt-1 text-gray-800"> v.{{ insecureAppData?.version }} </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { ref } from 'vue'
import U2Logo from '@assets/img/u2-login-logo-small-black.svg?component'
import { queries } from '@js/query'
import AppNotifications from '@js/components/notifications/AppNotifications.vue'
import SystemMessages from '@js/components/system-messages/SystemMessages.vue'
import Translator from '@js/translator'

const { data: insecureAppData, isLoading } = useQuery(queries.app.insecureData)

const loginLogoPath = '/api/dynamic-assets/login-logo'

const imgLoaded = ref(false)
const style = `background-image: url('/api/dynamic-assets/background-image');`
const img = new Image()
img.onload = () => {
  imgLoaded.value = true
}
img.src = '/api/dynamic-assets/background-image'
</script>
