import axios from 'axios'
import Routing from '@js/Routing'
import type { LayoutCollection } from '@js/model/layoutCollection'
import type { Datasheet, GroupViewData } from '@js/model/datasheet'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'
import type { Period } from '@js/model/period'

type GroupViewDataQuery = {
  period: Period['id']
  unitHierarchy: UnitHierarchy['id']
  layout: Datasheet['id']
  layoutCollection: LayoutCollection['id']
}

function fetchByQuery(query: GroupViewDataQuery) {
  return axios.get<GroupViewData>(Routing.generate('u2_layout_groupviewdata'), {
    params: {
      ...query,
    },
  })
}

function downloadXls(params: {
  datasheetCollection: LayoutCollection['id']
  datasheet: Datasheet['id']
  period: Period['id']
  unitHierarchy: UnitHierarchy['id']
}) {
  return axios.get<Blob>(Routing.generate('u2_datasheets_unithierarchyview_export', params), {
    responseType: 'blob',
  })
}

export const groupViewDataApi = {
  downloadXls,
  fetchByQuery,
}
