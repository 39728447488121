import { syncRef, useLocalStorage, useSessionStorage } from '@vueuse/core'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Datasheet, Field } from '@js/model/datasheet'
import type { LayoutCollection } from '@js/model/layoutCollection'
import type { Period } from '@js/model/period'
import type { Unit } from '@js/model/unit'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'

export type SelectedView = 'unit' | 'group'

export type LayoutParameters = {
  unit?: Unit['id']
  period?: Period['id']
  layout?: Datasheet['id']
  field?: Field['id']
  layoutCollection?: LayoutCollection['id']
  selectedView: SelectedView
  unitHierarchy?: UnitHierarchy['id']
}

export const useLayoutParametersStore = defineStore('layout-parameters', () => {
  const parametersLocal = useLocalStorage<LayoutParameters>('layout:params', {
    selectedView: 'unit',
    unit: undefined,
    unitHierarchy: undefined,
    period: undefined,
    layout: undefined,
    field: undefined,
    layoutCollection: undefined,
  })
  const parameters = useSessionStorage<LayoutParameters>('layout:params', parametersLocal.value)

  syncRef(parameters, parametersLocal, { direction: 'ltr' })

  return {
    parameters,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLayoutParametersStore, import.meta.hot))
}
