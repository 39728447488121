import axios from 'axios'
import Routing from '@js/Routing.ts'
import type { SystemSettings } from '@js/model/system_setting.ts'

type About = {
  version?: string
}

export type InsecureData = {
  backgroundColor: SystemSettings['loginColor']
  version: string
  loginRules: {
    minLength: SystemSettings['securityPasswordRulesMinLength']
    requireNumber: SystemSettings['securityPasswordRulesRequireNumber']
    requireNonAlphanumericCharacter: SystemSettings['securityPasswordRulesRequireNonAlphanumericCharacter']
    requireUppercaseLetter: SystemSettings['securityPasswordRulesRequireUppercaseLetter']
    requireLowercaseLetter: SystemSettings['securityPasswordRulesRequireLowercaseLetter']
  }
}
export const aboutApi = {
  fetch: function () {
    return axios.get<About>(Routing.generate('u2_about'))
  },
  fetchInsecureAppData: function () {
    return axios.get<InsecureData>(Routing.generate('u2_insecuredata__invoke'))
  },
}
