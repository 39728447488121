import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { groupViewDataApi } from '@js/api/groupViewDataApi'
import { unitViewDataApi } from '@js/api/unitViewDataApi'
import { PermissionMasks } from '@js/model/permission'
import { datasheetCollectionApi } from '@js/api/datasheetCollectionApi'
import type { LayoutCollection } from '@js/model/layoutCollection'
import type { MaybeRef } from '@vueuse/core'
import type { LayoutCollectionGroupViewDataParams } from '@js/composable/useLayoutCollectionGroupViewDataQuery'
import type { LayoutCollectionUnitViewDataParams } from '@js/composable/useLayoutCollectionUnitViewDataQuery'

export const layoutCollections = createQueryKeys('layoutCollections', {
  all: {
    queryKey: null,
    queryFn: () =>
      datasheetCollectionApi.fetchByQuery({ pagination: false, permission: PermissionMasks.VIEW }),
  },
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      datasheetCollectionApi
        .fetchByQuery({
          ...unref(filters),
        })
        .then((response) => response.data),
  }),
  single: (id: MaybeRef<LayoutCollection['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => datasheetCollectionApi.fetchById(unref(id)).then((response) => response.data),
      contextQueries: {
        layouts: {
          queryKey: null,
          queryFn: () =>
            datasheetCollectionApi
              .fetchAssignedLayouts(unref(id))
              .then((response) => response.data),
        },
        itemCountryReports: {
          queryKey: null,
          queryFn: () =>
            datasheetCollectionApi
              .fetchAssignedItemCountryReports(unref(id))
              .then((response) => response.data),
        },
        allAssignedLayouts: {
          queryKey: null,
          queryFn: () =>
            datasheetCollectionApi
              .fetchAssignedLayoutsForAdministrators(unref(id))
              .then((response) => response.data),
        },
      },
    }
  },
  unitViewData: (
    layoutCollectionUnitViewDataParams: MaybeRef<LayoutCollectionUnitViewDataParams>
  ) => {
    return {
      queryKey: [layoutCollectionUnitViewDataParams],
      queryFn: () => {
        const params = unref(layoutCollectionUnitViewDataParams)
        return unitViewDataApi
          .fetchByQuery({
            layout: params.layoutId,
            period: params.periodId,
            unit: params.unitId,
            layoutCollection: params.layoutCollectionId,
          })
          .then((response) => response.data)
      },
    }
  },
  groupViewData: (
    layoutCollectionGroupViewDataParams: MaybeRef<LayoutCollectionGroupViewDataParams>
  ) => {
    return {
      queryKey: [layoutCollectionGroupViewDataParams],
      queryFn: () => {
        const params = unref(layoutCollectionGroupViewDataParams)
        return groupViewDataApi
          .fetchByQuery({
            layout: params.layoutId,
            period: params.periodId,
            unitHierarchy: params.unitHierarchyId,
            layoutCollection: params.layoutCollectionId,
          })
          .then((response) => response.data)
      },
    }
  },
})
