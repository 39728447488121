import axios from 'axios'
import type { SetRequired } from 'type-fest'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import type { HydraCollectionResponse, NewApiResource } from '@js/types'
import type { Datasheet, LayoutItem } from '@js/model/datasheet'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { LayoutCollection } from '@js/model/layoutCollection'

export const basePath = '/api/layouts'

export type LayoutCollectionQuery = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[name]'?: SortingDirection
  'sort[group]'?: SortingDirection
  'fields.item.refId'?: LayoutItem['refId']
  'fields.item.id'?: LayoutItem['id']
  permission?: 1 | 4 | 8 | 128
  search?: string | Array<string>
}

type UpdatedLayout = SetRequired<Partial<Datasheet>, 'id'>
type NewLayout = Pick<Datasheet, 'name' | 'group' | 'public'>

function fetchByQuery(query?: LayoutCollectionQuery) {
  return axios.get<HydraCollectionResponse<Datasheet>>(basePath, {
    params: { ...query },
  })
}

function fetchLayoutCollections(id: Datasheet['id']) {
  return axios.get<HydraCollectionResponse<LayoutCollection>>(
    `${basePath}/${id}/layout-collections`
  )
}

function downloadLayoutTemplate(id: Datasheet['id']) {
  return axios.get<Blob>(basePath + `/${id}/template`, { responseType: 'blob' })
}

function deleteLayoutTemplate(id: Datasheet['id']) {
  return axios.delete(basePath + `/${id}/template`)
}

function uploadTemplate(id: Datasheet['id'], payload: File, config?: AxiosRequestConfig) {
  const formData = new FormData()
  formData.append('template', payload)

  return axios.post(`${basePath}/${id}/template`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    ...config,
  })
}
const update = function (payload: UpdatedLayout) {
  return axios.patch<Datasheet>(`${basePath}/${payload.id}`, payload)
}
const create = function (payload: NewLayout) {
  return axios.post<NewApiResource<Datasheet>, AxiosResponse<Datasheet>>(`${basePath}`, payload)
}

export const datasheetApi = {
  basePath,
  fetchByQuery,
  fetchLayoutCollections,
  deleteLayoutTemplate,
  uploadTemplate,
  downloadLayoutTemplate,
  save: function (payload: UpdatedLayout | NewLayout) {
    return 'id' in payload ? update(payload) : create(payload)
  },
  delete: function (id: Datasheet['id']) {
    return axios.delete(`${basePath}/${id}`)
  },
  fetchAll: function () {
    return fetchByQuery({ pagination: false })
  },
  fetchById: function (id: Datasheet['id']) {
    return axios.get<Datasheet>(`${basePath}/${id}`)
  },
}
