import { mergeQueryKeys } from '@lukemorales/query-key-factory'
import { app } from '@js/query/app.ts'
import { workflowBindings } from '@js/query/workflowBindings'
import { actionTypes } from '@js/query/actionTypes'
import { attachments } from '@js/query/attachments'
import { authorizationProfiles } from '@js/query/authorizationProfiles'
import { authorizations } from '@js/query/authorizations'
import { comments } from '@js/query/comments'
import { conditionTypes } from '@js/query/conditionTypes'
import { countries } from '@js/query/countries'
import { currencies } from '@js/query/currencies'
import { dashboards } from '@js/query/dashboards'
import { dataTransferTypes } from '@js/query/dataTransferTypes'
import { document } from '@js/query/document'
import { documentTemplates } from '@js/query/documentTemplates'
import { dynamicAssets } from '@js/query/dynamicAssets'
import { exchangeRates } from '@js/query/exchangeRates'
import { files } from '@js/query/files'
import { fileTypes } from '@js/query/fileTypes'
import { importTypes } from '@js/query/importTypes'
import { items } from '@js/query/items'
import { itemUnitHierarchyValues } from '@js/query/itemUnitHierarchyValues'
import { itemUnitValues } from '@js/query/itemUnitValues'
import { layoutCollections } from '@js/query/layoutCollections'
import { layouts } from '@js/query/layouts'
import { menu } from '@js/query/menu'
import { periods } from '@js/query/periods'
import { savedFilters } from '@js/query/savedFilters'
import { savedFilterSubscriptions } from '@js/query/savedFiltersSubscriptions'
import { statuses } from '@js/query/statuses'
import { systemMessages } from '@js/query/systemMessages'
import { systemSettings } from '@js/query/systemSettings'
import { tasks } from '@js/query/tasks'
import { unitHierarchies } from '@js/query/unitHierarchies'
import { unitProperties } from '@js/query/unitProperties'
import { units } from '@js/query/units'
import { userGroups } from '@js/query/userGroups'
import { users } from '@js/query/users'
import { workflows } from '@js/query/workflows'
import { workflowTransitions } from '@js/query/workflowTransitions'

export const queries = mergeQueryKeys(
  app,
  actionTypes,
  attachments,
  authorizationProfiles,
  authorizations,
  comments,
  conditionTypes,
  countries,
  currencies,
  dashboards,
  dataTransferTypes,
  document,
  documentTemplates,
  dynamicAssets,
  exchangeRates,
  files,
  fileTypes,
  importTypes,
  items,
  itemUnitHierarchyValues,
  itemUnitValues,
  layoutCollections,
  layouts,
  menu,
  periods,
  savedFilters,
  savedFilterSubscriptions,
  statuses,
  systemMessages,
  systemSettings,
  tasks,
  unitHierarchies,
  unitProperties,
  units,
  userGroups,
  users,
  workflows,
  workflowBindings,
  workflowTransitions
)
