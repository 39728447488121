import axios from 'axios'
import { basePath as layoutCollectionBasePath } from '@js/api/datasheetCollectionApi'
import type { Check } from '@js/model/check'
import type { CheckState } from '@js/model/checkstate'
import type { PermissionMask } from '@js/model/permission'
import type { HydraCollectionResponse } from '@js/types'
import type { Task } from '@js/model/task'
import type { LayoutCollection } from '@js/model/layoutCollection'
import type { User } from '@js/model/user'

export const basePath = '/api/tasks'
function fetchLayoutCollections(taskId: Task['id'], permission?: PermissionMask) {
  return axios.get<HydraCollectionResponse<LayoutCollection>>(
    `${basePath}/${taskId}/layout-collections`,
    {
      params: { permission },
    }
  )
}

function fetchPermittedUsers(taskId: Task['id']) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${taskId}/permitted-users`)
}

function addLayoutCollection(taskId: Task['id'], layoutCollectionId: LayoutCollection['id']) {
  return axios.post<HydraCollectionResponse<LayoutCollection>>(
    `${basePath}/${taskId}/layout-collections`,
    {
      layoutCollection: `${layoutCollectionBasePath}/${layoutCollectionId}`,
    }
  )
}

function removeLayoutCollection(taskId: Task['id'], layoutCollectionId: LayoutCollection['id']) {
  return axios.delete<HydraCollectionResponse<LayoutCollection>>(
    `${basePath}/${taskId}/layout-collections/` + layoutCollectionId
  )
}

function fetchTaskById(taskId: Task['id']) {
  return axios.get<Task>(`/api/tasks/${taskId}`)
}

function fetchCheckStates(taskId: Task['id'], checkIri: Check['@id']) {
  return axios.get<HydraCollectionResponse<CheckState>>(`/api/tasks/${taskId}/check-states`, {
    params: {
      check: checkIri,
      pagination: false,
    },
  })
}

export const taskApi = {
  addLayoutCollection,
  basePath,
  fetchCheckStates,
  fetchLayoutCollections,
  fetchPermittedUsers,
  fetchTaskById,
  removeLayoutCollection,
}
