import { logDeprecatedRouteUsage } from '@js/router/helpers'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'TaxAccountingItemList',
    path: '/configuration/item',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetItemList',
      }
    },
  },
  {
    name: 'ItemEditLegacy',
    path: '/configuration/item/:id/edit',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetItemEdit',
        params: {
          id: to.params.id,
        },
      }
    },
  },
  {
    name: 'ItemNewLegacy',
    path: '/configuration/item/new',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetItemNew',
      }
    },
  },
  {
    name: 'TaxAccountingLayoutList',
    path: '/configuration/layout',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetList',
      }
    },
  },
  {
    name: 'LayoutEdit',
    path: '/configuration/layout/:id/edit',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetEdit',
        params: {
          id: to.params.id,
        },
      }
    },
  },
  {
    name: 'LayoutNew',
    path: '/configuration/layout/new',
    redirect: (to) => {
      logDeprecatedRouteUsage(to)
      return {
        name: 'DatasheetNew',
      }
    },
  },
] as Array<RouteRecordRaw>
