import axios, { type AxiosResponse } from 'axios'
import type { LayoutItem } from '@js/model/datasheet'
import type { HydraCollectionResponse } from '@js/types'

const basePath = '/api/items'

export const itemApi = {
  delete: function (payload: Pick<LayoutItem, '@id'>) {
    if (!payload['@id']) throw new Error('Unable to delete record without @id')

    return axios.delete<LayoutItem>(payload['@id'])
  },
  create: function (payload: Partial<LayoutItem> & Pick<LayoutItem, 'type'>) {
    return axios.post<Partial<LayoutItem>, AxiosResponse<LayoutItem>>(`${basePath}`, payload)
  },
  update: function (payload: Partial<LayoutItem> & Pick<LayoutItem, '@id'>) {
    return axios.put<Partial<LayoutItem> & Pick<LayoutItem, '@id'>, AxiosResponse<LayoutItem>>(
      payload['@id'],
      payload
    )
  },

  fetchByIri: function (iri: LayoutItem['@id']) {
    return axios.get<LayoutItem>(iri)
  },
  fetchById: function (id: LayoutItem['id']) {
    return axios.get<LayoutItem>(`${basePath}/${id}`)
  },
  fetchAll: function () {
    return axios.get<HydraCollectionResponse<LayoutItem>>(basePath, {
      params: { pagination: false },
    })
  },
  basePath,
}
