import axios from 'axios'
import Routing from '@js/Routing.ts'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { RoleResource } from '@js/model/role'
import type { Unit } from '@js/model/unit'
import type { User, UserSettings } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'
import type { Dashboard } from '@js/model/dashboard'

export const basePath = '/api/users'

export const userApi = {
  fetchAll: function () {
    return axios.get<HydraCollectionResponse<User>>(basePath, {
      params: { pagination: false, sort: { username: 'ASC' } },
    })
  },
  fetchById: function (id: User['id']) {
    return axios.get<User>(`${basePath}/${id}`)
  },
  fetchByIri: function (iri: ApiResourceId) {
    return axios.get<User>(iri)
  },
  fetchAssignedDashboards: function (id: User['id']) {
    return axios.get<HydraCollectionResponse<Dashboard>>(`${basePath}/${id}` + `/dashboards`)
  },
  delete: function (id: User['id']) {
    return axios.delete(`${basePath}/${id}`)
  },
  fetchAssignedRoles: function (id: User['id']) {
    return axios.get<HydraCollectionResponse<RoleResource>>(`${basePath}/${id}/roles`, {
      params: { pagination: false },
    })
  },
  fetchAssignedUnits: function (id: User['id']) {
    return axios.get<HydraCollectionResponse<Unit>>(`${basePath}/${id}/units`, {
      params: { pagination: false },
    })
  },
  fetchAssignedUserGroups: function (id: User['id']) {
    return axios.get<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
      params: { pagination: false },
    })
  },
  fetchInheritedRoles: function (id: User['id']) {
    return axios.get<HydraCollectionResponse<RoleResource>>(`${basePath}/${id}/inherited-roles`, {
      params: { pagination: false },
    })
  },
  updateAssignedRoles: function (
    id: User['id'],
    updatedRoles: Array<NonNullable<RoleResource['@id']>>
  ) {
    return axios.put<HydraCollectionResponse<RoleResource>>(`${basePath}/${id}/roles`, {
      roles: updatedRoles,
    })
  },
  updateAssignedUnits: function (id: User['id'], updatedUnits: Array<NonNullable<Unit['@id']>>) {
    return axios.put<HydraCollectionResponse<Unit>>(`${basePath}/${id}/units`, {
      units: updatedUnits,
    })
  },
  fetchInheritedUnits: function (id: User['id']) {
    return axios.get<HydraCollectionResponse<Unit>>(`${basePath}/${id}/inherited-units`, {
      params: { pagination: false },
    })
  },
  fetchSettings: function (id: User['id']) {
    return axios.get<UserSettings>(`${basePath}/${id}/settings`)
  },
  updateAssignedUserGroups: function (
    id: User['id'],
    updatedUserGroups: Array<NonNullable<UserGroup['@id']>>
  ) {
    return axios.put<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
      groups: updatedUserGroups,
    })
  },
  resetTrustedDevices: function (userIri: User['@id']) {
    return axios.put(`${userIri}/reset-trusted-devices`, {})
  },
  unlock: function (id: User['id']) {
    return axios.post(Routing.generate('u2_user_unlock', { id }))
  },
  fetchAuthorisations: function (userId: User['id']) {
    return axios.get(Routing.generate('u2_user_userrightsjson', { id: userId }))
  },
  basePath,
}
