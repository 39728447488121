import invariant from 'tiny-invariant'
import { computed, ref } from 'vue'
import { useArrayMap } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse'
import { useQueries } from '@tanstack/vue-query'
import { type RouteMeta, type RouteRecordNormalized, useRoute, useRouter } from 'vue-router'
import { getReadableTaskTypeByShortName, getTaskType } from '@js/model/task'
import { queries } from '@js/query'
import { useAuthStore } from '@js/stores/auth'
import useDashboardsAllAssignedQuery from '@js/composable/useDashboardsAllAssignedQuery'
import useLayoutCollectionsAllQuery from '@js/composable/useLayoutCollectionsAllQuery'
import useSavedFilterAllQuery from '@js/composable/useSavedFilterAllQuery'
import { useLayoutParametersStore } from '@js/stores/layout-parameters'
import type { Merge, SetRequired } from 'type-fest'
import type { LayoutCollection } from '@js/model/layoutCollection'
import type { Datasheet } from '@js/model/datasheet'
import type { Icon } from '@js/utilities/name-lists'
import type { Unit } from '@js/model/unit'
import type { Period } from '@js/model/period'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'

export type SearchResult = {
  icon: Icon
  name: string
  url: string
  description?: string
}

export function useGlobalSearch() {
  const query = ref('')
  const router = useRouter()
  const authStore = useAuthStore()

  type RouteWithGlobalSearch = Merge<
    RouteRecordNormalized,
    { meta: SetRequired<RouteMeta, 'globalSearch'> }
  >

  const authorisedItems = computed(() =>
    router
      .getRoutes()
      .filter((route): route is RouteWithGlobalSearch => {
        if (!route.meta.globalSearch) {
          return false
        }

        const auth = route.meta.auth
        if (!auth || typeof auth === 'boolean') {
          return true
        }

        return authStore.hasRoleOrAuthorization(auth)
      })
      .map((result) => {
        const globalSearch = result.meta.globalSearch
        return {
          icon: globalSearch.icon,
          name: globalSearch.name(),
          url: result.path,
        }
      })
  )

  const savedFilterItems = computed<Array<SearchResult>>(
    () =>
      allSavedFilterQuery.items.value.map((result) => {
        const routeLocation = {
          name: getTaskType(result.taskShortName) + 'List',
          query: { f: result.id },
        }
        return {
          icon: 'filter',
          name: result.name + ' - ' + getReadableTaskTypeByShortName(result.taskShortName),
          url: router.resolve(routeLocation).href,
          description: result.description,
        }
      }) ?? []
  )

  const dashboardItems = computed<Array<SearchResult>>(
    () =>
      allDashboardsQuery.items.value.map((dashboard) => ({
        icon: 'dashboard',
        name: dashboard.title,
        url: router.resolve({
          name: 'AppDashboard',
          params: { id: dashboard.id, slug: dashboard.slug },
        }).href,
      })) ?? []
  )

  const layoutCollectionsQuery = useLayoutCollectionsAllQuery()

  const layoutQueryDefinitions = computed(() => {
    if (layoutCollectionsQuery.isLoading.value) {
      return []
    }

    return layoutCollectionsQuery.items.value.map(
      (layoutCollection) => queries.layoutCollections.single(layoutCollection.id)._ctx.layouts
    )
  })
  const layoutQueries = useQueries({ queries: layoutQueryDefinitions })

  const layoutParamStore = useLayoutParametersStore()

  const route = useRoute()

  const layoutItems = computed<Array<SearchResult>>(() => {
    return layoutQueries.value
      .filter((query) => !query.isLoading)
      .map((query) => {
        const data = query.data
        invariant(data)
        const layoutCollection = layoutCollectionsQuery.items.value.find((layoutCollection) => {
          return data['@id'].includes(layoutCollection.id)
        }) as LayoutCollection

        return data['hydra:member'].map((layout: Datasheet) => {
          const queryParameter = {
            period: route.query.period ? layoutParamStore.parameters.period : undefined,
          } as {
            unit?: Unit['id']
            period?: Period['id']
            unitHierarchy?: UnitHierarchy['id']
          }

          if (route.query.unit) {
            queryParameter.unit = route.query.unit ? Number(route.query.unit) : undefined
          }

          if (!route.query.unit && route.query.unitHierarchy) {
            queryParameter.unitHierarchy = route.query.unitHierarchy
              ? Number(route.query.unitHierarchy)
              : undefined
          }

          return {
            icon: 'list' as const,
            name: `${layout.name} (${layoutCollection.name})`,
            url: router.resolve({
              name: 'DatasheetCollectionSheetView',
              params: {
                id: layoutCollection.id,
                sheetId: layout.id,
              },
              query: queryParameter,
            }).href,
          }
        })
      })
      .flat()
  })

  const allDashboardsQuery = useDashboardsAllAssignedQuery(() => authStore.user?.id)
  const allSavedFilterQuery = useSavedFilterAllQuery()

  const loading = computed(() => {
    return [
      allDashboardsQuery.isLoading.value,
      allSavedFilterQuery.isLoading.value,
      layoutCollectionsQuery.isLoading.value,
    ].includes(true)
  })

  const allSearchItems = computed(() => {
    return [
      ...dashboardItems.value,
      ...layoutItems.value,
      ...savedFilterItems.value,
      ...authorisedItems.value,
    ]
  })

  const { results } = useFuse(query, allSearchItems, {
    fuseOptions: {
      keys: ['name'],
      isCaseSensitive: false,
    },
  })

  return {
    results: useArrayMap(results, (result) => result.item),
    query,
    loading,
  }
}
