import { createQueryKeys } from '@lukemorales/query-key-factory'
import { type MaybeRefOrGetter, toValue, unref } from 'vue'
import { type ItemUnitValueCollectionQuery, itemUnitValueApi } from '@js/api/itemUnitValueApi'
import type { MaybeRef } from '@vueuse/core'
import type { ItemUnitValue } from '@js/model/datasheet'

export const itemUnitValues = createQueryKeys('itemUnitValues', {
  single: (id: MaybeRef<ItemUnitValue['id']>) => ({
    queryKey: [id],
    queryFn: () => itemUnitValueApi.fetchById(unref(id)).then((response) => response.data),
  }),
  list: (filters?: MaybeRefOrGetter<ItemUnitValueCollectionQuery>) => ({
    queryKey: [filters],
    queryFn: () =>
      itemUnitValueApi.fetchByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),
})
